import api from '../../services/api'

export const getProductsFetch = () => {
  return {
    type: 'GET_PRODUCTS_FETCH'
  }
}

export const getProductsSuccess = products => {
  return {
    type: 'GET_PRODUCTS_SUCCESS',
    payload: {
      products: products.data
    }
  }
}

export const getProductsError = () => {
  return {
    type: 'GET_PRODUCTS_ERROR'
  }
}

export const getPriceFetch = () => {
  return {
    type: 'GET_PRICE_FETCH'
  }
}

export const getPriceSuccess = prices => {
  return {
    type: 'GET_PRICE_SUCCESS',
    payload: {
      prices: prices.data
    }
  }
}

export const getPriceError = () => {
  return {
    type: 'GET_PRICE_ERROR'
  }
}

export const setSearchTerm = term => {
  return {
    type: 'SET_SEARCH_TERM',
    payload: {
      searchTerm: term
    }
  }
}

export const getProducts = () => {  
  return dispatch => {
    dispatch(getProductsFetch())
    api.get('menu')
      .then(response => {
        dispatch(getProductsSuccess(response))
        dispatch(getPrices())
      })
      .catch(response => dispatch(getProductsError()))
  }
}

export const getPrices = () => {
  return dispatch => {
    dispatch(getPriceFetch())
    api.get('materials')
      .then(response => dispatch(getPriceSuccess(response)))
      .catch(response => dispatch(getPriceError()))
  }
}