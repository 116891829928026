import React, { Component } from 'react'

import Child from './Child'

class ChildPrice extends Component {
  render() {
    let category = this.props.name;
    return (
      <>
        <div className='product-category-title'>
          <div className='product-group-name'>{this.props.name}</div>
        </div>
        {this.props.options.map((val, key) => {
          return <Child key={key} classes={this.props.classes} name={val.name} description={val.description} ncrId={val.ncr_id} category={category} />
        })}
      </>
    )
  }
}

export default ChildPrice