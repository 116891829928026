import React, { Component } from 'react'

import Default from './products/Default'
import HalfList from './products/HalfList'
import ThirdList from './products/ThirdList'
import ChildPrice from './products/ChildPrice'
// import IndividualShare from './products/IndividualShare'

class Product extends Component {
  buildClass = catKey => {
    return 'product product-' + catKey
  }

  render() {
    switch (this.props.categoryType) {
      case 1:
      case 5:
        return <Default
          classes={this.buildClass(this.props.catKey)}
          category={this.props.category}
          name={this.props.name}          
          description={this.props.description}
          price={this.props.price}
          prodKey={this.props.prodKey}
          index={this.props.index}
        />
      case 2:
        return <HalfList
          classes={this.buildClass(this.props.catKey)}
          category={this.props.category}
          name={this.props.name}
          description={this.props.description}
          options={this.props.options}
          prodKey={this.props.prodKey}
          index={this.props.index}
        />
      case 3:
        return <ThirdList
          classes={this.buildClass(this.props.catKey)}
          category={this.props.category}
          name={this.props.name}
          description={this.props.description}
          options={this.props.options}
          prodKey={this.props.prodKey}
          index={this.props.index}
        />
      case 4:
        return <ChildPrice
          classes={this.buildClass(this.props.catKey)}
          name={this.props.name}
          description={this.props.description}
          options={this.props.options}
          index={this.props.index}
        />
      // case 5:
      //   return <IndividualShare
      //     classes={this.buildClass(this.props.catKey)}
      //     name={this.props.name}
      //     description={this.props.description}
      //     options={this.props.options}
      //     index={this.props.index}
      //   />
      default:
        return <></>
    }
  }
}

export default Product