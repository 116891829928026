import React, { Component } from 'react'
import { Provider } from 'react-redux'
import store from './store'
import './style.css'

import Routes from './components/Routes'

class App extends Component {
  render() {
    return (
       <Provider store={store}>
         <Routes />
       </Provider>      
    )
  }
}

export default App
