import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import DefaultImage from '../placeholders/DefaultImage'
import { PriceLoader } from '../placeholders/PriceLoader'

import dots from '../../assets/img/dots.png'

class ThirdList extends Component {
  getPrice = product_id => {
    let price = this.props.prices.filter(val => {
      return val.id === product_id
    })

    if (price.length) {
      return price[0].price
    }

    return false
  }

  renderPrice() {
    const { options } = this.props

    if (options && this.props.prices.length) {
      return options.map((val, key) => {
        return (
          <div key={key}>
            <p className='product-price-label text-capitalize'>{val.name}</p>
            <p className='product-price'>R$ {this.getPrice(val.ncr_id).toFixed(2)}</p>
          </div>
        )
      })
    }

    return <PriceLoader />
  }

  isFirst = () => {
    if (this.props.prodKey === 0) {
      return true
    }

    return false
  }

  renderFirst = () => {
    if (this.isFirst() && !this.props.searchTerm.length) {
      return (
        <div className='product-category-title'>
          <div className='product-group-name'>{this.props.category}</div>
        </div>
      )
    }

    return <></>
  }

  render() {
    return (
      <>
        {this.renderFirst()}
        <div className={this.props.classes}>
          <div className='product-image'>
            {(() => {                                
                  return <DefaultImage />;                
            })()}
          </div>
          <Link className='no-decoration flex-shrink product-info' to={`/produto?id=${this.props.index}`}>
            <p className='product-name'>{this.props.name}</p>
            <p className='product-description'>{this.props.description}</p>
            <div className='product-footer'>
              {this.renderPrice()}
              <img src={dots} alt='Abrir produto' />
            </div>
          </Link>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    prices: state.productsReducer.prices,
    searchTerm: state.productsReducer.searchTerm
  }
}

export default connect(mapStateToProps)(ThirdList)