import React from 'react'

import logo from '../../assets/img/2.png'

const PizzasImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Pizzas' style={{ width: '100%' }} />
  </div>
)

export default PizzasImage