import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import DefaultImage from '../placeholders/DefaultImage'
import PizzasImage from '../placeholders/PizzasImage'
import EntradasImage from '../placeholders/EntradasImage'
import BebidasAlcoolicasImage from '../placeholders/BebidasAlcoolicasImage'
import DrinksImage from '../placeholders/DrinksImage'
import BebidasSemAlcoolImageImage from '../placeholders/BebidasSemAlcoolImage'
import { PriceLoader } from '../placeholders/PriceLoader'
import dots from '../../assets/img/dots.png'

class Child extends Component {
  getPrice = product_id => {
    let price = this.props.prices.filter(val => {
      return val.id === product_id
    })

    if (price.length) {
      return price[0].price.toFixed(2)
    }

    return false
  }

  renderPrice() {
    if (this.props.ncrId) {
      return <p className='product-price'>R$ {this.getPrice(this.props.ncrId)}</p>
    }

    return <PriceLoader />
  }
  
  render() {
    return (
      <div className={this.props.classes}>
        <div className='product-image'>
        <Link className='no-decoration product-info' to={`/produto?id=${this.props.index}`}>
          {(() => {
           if (this.props.category === "Entradas") {
            return <EntradasImage />;                  
          } else if (this.props.category === "Pizzas") {
            return <PizzasImage />;                                       
          } else if (this.props.category === "Bebidas Alcoolicas") {
            return <BebidasAlcoolicasImage />;
          } else if (this.props.category === "Drinks") {
            return <DrinksImage />;
          } else if (this.props.category === "Bebidas Sem Alcool") {
            return <BebidasSemAlcoolImageImage />;
          } else  if (this.props.category === "Sobremesas") {
            return <DefaultImage />;
          } else  if (this.props.category === "Vinhos") {
            return <DefaultImage />;
          } else{
            return <DefaultImage />;
          }
          })()}
        </Link>
        </div>
        <Link className='no-decoration product-info' to={`/produto?id=${this.props.index}`}>
          <p className='product-name'>{this.props.name}</p>
          <p className='product-description'>{this.props.description}</p>
          <div className='product-footer'>
            {this.renderPrice()}
            <img src={dots} alt='Abrir produto' />
          </div>
        </Link>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    prices: state.productsReducer.prices
  }
}

export default connect(mapStateToProps)(Child)