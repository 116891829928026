import axios from 'axios';

//const endpoint = 'http://localhost/';
//const endpoint = 'http://192.168.2.164/';
const endpoint = 'https://core.soffiopizzeria.com.br/';

const api = axios.create({
  baseURL: endpoint + 'api/',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

api.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('authToken'); // Obtém o token do localStorage no momento da solicitação
    if (authToken) {      
      config.headers.Authorization = `Bearer ${authToken}`; // Adiciona o token ao cabeçalho
    }    
    return config;
  },
  (error) => {    
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    //console.log(response);
      return response;
  },
  (error) => {                  
      if ( error.response.status == "401"){
        localStorage.removeItem('numeroMesa');
        localStorage.removeItem('idPedido');
        localStorage.removeItem('idArquivo');
        localStorage.removeItem('loginId');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('status');
        localStorage.removeItem('userCodigo');
        localStorage.removeItem('authToken');
        window.location.href = '/';              
      }
      return Promise.reject(error);
      
  }
);

export default api;