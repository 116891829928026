import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import DefaultImage from '../placeholders/DefaultImage'
import PizzasImage from '../placeholders/PizzasImage'
import EntradasImage from '../placeholders/EntradasImage'
import BebidasAlcoolicasImage from '../placeholders/BebidasAlcoolicasImage'
import DrinksImage from '../placeholders/DrinksImage'
import BebidasSemAlcoolImageImage from '../placeholders/BebidasSemAlcoolImage'
import { PriceLoader } from '../placeholders/PriceLoader'
import dots from '../../assets/img/dots.png'

class Default extends Component {
  renderPrice() {
    if (this.props.price) {
      return <p className='product-price'>R$ {this.props.price.toFixed(2)}</p>
    }

    return <PriceLoader />
  }

  isFirst = () => {
    if (this.props.prodKey === 0) {
      return true
    }

    return false
  }

  renderFirst = () => {
    if (this.isFirst() && !this.props.searchTerm.length) {
      return (
        <div className='product-category-title'>
          <div className='product-group-name'>{this.props.category}</div>
        </div>
      )
    }

    return <></>
  }
  
  render() {
    return (
      <>
        {this.renderFirst()}
        <div className={this.props.classes}>        
          <div className='product-image'>
            <Link className='no-decoration product-info' to={`/produto?id=${this.props.index}`}>
              {(() => {
                if (this.props.category === "Entradas") {
                  return <EntradasImage />;                  
                } else if (this.props.category === "Pizzas") {
                  return <PizzasImage />;                                       
                } else if (this.props.category === "Bebidas Alcoolicas") {
                  return <BebidasAlcoolicasImage />;
                } else if (this.props.category === "Drinks") {
                  return <DrinksImage />;
                } else if (this.props.category === "Bebidas Sem Alcool") {
                  return <BebidasSemAlcoolImageImage />;
                } else  if (this.props.category === "Sobremesas") {
                  return <DefaultImage />;
                } else  if (this.props.category === "Vinhos") {
                  return <DefaultImage />;
                } else{
                  return <DefaultImage />;
                }
              })()}
            </Link>
          </div>
          <Link className='no-decoration product-info' to={`/produto?id=${this.props.index}`}>
            <p className='product-name'>{this.props.name}</p>
            <p className='product-description'>{this.props.description}</p>
            <div className='product-footer'>
              {this.renderPrice()}
              <img src={dots} alt='Abrir produto' />
            </div>
          </Link>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchTerm: state.productsReducer.searchTerm
  }
}

export default connect(mapStateToProps)(Default)