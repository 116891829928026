import React, { Component, createRef  } from 'react'
import { connect } from 'react-redux'

import searchButton from '../assets/img/search-button.png'
import { setSearchTerm } from '../store/actions/products'

class Searchbar extends Component {
  constructor(props) {
    super(props);
    this.searchInputRef = createRef(); // Criar uma referência para o campo de busca
  }

  handleFocus = () => {
    if (this.searchInputRef.current) {
      setTimeout(() => {
        const topPosition = this.searchInputRef.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: topPosition - 15, behavior: 'smooth' });
      }, 100);
    }
  };

  render() {
    const { searchTerm, numeroMesa } = this.props;

    return (
      <div className='searchbar'>
        <div>
          <input 
            type='text' 
            name="term" 
            onChange={e => this.props.setSearchTerm(e.target.value)} 
            placeholder='Buscar no cardápio' 
            value={searchTerm} 
            onFocus={this.handleFocus} // Evento onFocus que chama a função para rolar a página
            ref={this.searchInputRef}  // Atribuir a referência ao campo de input
          />
          <img src={searchButton} className='searchbar-icon' alt='Buscar' />
        </div>
        <div className="searchbar-mesa">
          M: {numeroMesa}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchTerm: state.productsReducer.searchTerm
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSearchTerm: term => dispatch(setSearchTerm(term))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Searchbar);