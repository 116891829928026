import React from 'react';
import Categories from './Categories';
import Searchbar from './Searchbar';

const Menu = () => {
  const status = localStorage.getItem('status');

  return (
    status === 'Fechada' || status === null ? null : (
      <>
        <Categories />
        <Searchbar numeroMesa={localStorage.getItem('numeroMesa')} />
      </>
    )
  );
};

export default Menu;