import React, { Component } from 'react'
import { connect } from 'react-redux'

import Product from './Product'

class Products extends Component {

  removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
  
  getPrice = product_id => {
    let price = this.props.prices.filter(val => {
      return val.id === product_id
    })

    if (price.length) {
      return price[0].price
    }

    return false
  }

  renderProducts = () => {
    const { products, searchTerm } = this.props;
  
    if (localStorage.getItem('status') === "Fechada" || localStorage.getItem('status') === null) {
      return <div key="1"></div>;
    };
  
    let filteredProductsByName = [];
    let filteredProductsByDescription = [];
  
    products.forEach((product, catKey) => {
      product.items.forEach((val, prodKey) => {
        const normalizedSearchTerm = this.removeAccents(searchTerm ? searchTerm.toLowerCase() : '');
          
        if (this.removeAccents(val.name.toLowerCase()).includes(normalizedSearchTerm)) {
          filteredProductsByName.push(
            <Product
              name={val.name}
              description={val.description}
              category={product.title}
              categoryType={product.category_type}
              catKey={catKey}
              prodKey={prodKey}
              price={this.getPrice(val.ncr_id)}
              key={val.id}
              options={val.options}
              index={val.id}
            />
          );
        }   
        else if (this.removeAccents(val.description.toLowerCase()).includes(normalizedSearchTerm)) {
          filteredProductsByDescription.push(
            <Product
              name={val.name}
              description={val.description}
              category={product.title}
              categoryType={product.category_type}
              catKey={catKey}
              prodKey={prodKey}
              price={this.getPrice(val.ncr_id)}
              key={val.id}
              options={val.options}
              index={val.id}
            />
          );
        }
      });
    });
      
    const orderedProducts = filteredProductsByName.concat(filteredProductsByDescription);
  
    if (orderedProducts.length === 0) {
      return <div>Nenhum produto encontrado.</div>;
    }
  
    return orderedProducts;
  }
  
  

  render() {
    return (
      <div className='product-list block-a'>
        {this.renderProducts()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    products: state.productsReducer.products,
    prices: state.productsReducer.prices,
    searchTerm: state.productsReducer.searchTerm,
    category_id: state.tabReducer.category_id,
    tab_id: state.tabReducer.tab_id
  }
}

export default connect(mapStateToProps)(Products)