import React, { Component } from 'react'
import { connect } from 'react-redux'

import CategoryItem from './CategoryItem'

class Categories extends Component {
  renderCategories() {
    const { products } = this.props

    return products.map((val, key) => {
      return <CategoryItem name={val.title} key={val.category_id} tabId={key} categoryId={val.category_id} />
    })
  }

  render() {
    return (
      <div className='categories'>
        {this.renderCategories()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    products: state.productsReducer.products
  }
}

export default connect(mapStateToProps)(Categories)