import ContentLoader from 'react-content-loader'

export const PriceLoader = () => (
  <ContentLoader 
    speed={2}
    width={56}
    height={22}
    viewBox="0 0 56 22"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="56" height="22" />
  </ContentLoader>
)