const INITIAL_STATE = {
  category_id: 1,
  tab_id: 0
}

export default function tab(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_TAB':
      return {
        category_id: action.payload.category_id,
        tab_id: action.payload.tab_id
      }
    default: return state
  }
}