  
import { combineReducers } from 'redux'

import productsReducer from './productsReducer'
import tabReducer from './tabReducer'

const rootReducer = combineReducers({
    productsReducer,
    tabReducer
})

export default rootReducer