import React, { Component } from 'react'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'

import { setTab } from '../store/actions/tabs'
import { setSearchTerm } from '../store/actions/products'

class CategoryItem extends Component {
  setTab = (categoryId, tabId) => {
    this.props.setSearchTerm('')
    setTimeout(() => {
      this.props.setTab(categoryId, tabId)
      scroller.scrollTo('product-' + tabId, {
        duration: 100,
        delay: 0,
        smooth: 'easeInQuad',
        offset: -165
      })
    }, 100)
  }

  buildClasses = () => {
    return 'category-item category-' + this.props.tabId
  }

  render() {
    const { tabId, categoryId, category_id, name } = this.props

    return (
      <div className={this.buildClasses()} onClick={() => this.setTab(categoryId, tabId)}>
        <p className={category_id === categoryId ? 'active' : ''}>
          {name}
        </p>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    category_id: state.tabReducer.category_id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTab: (category_id, tab_id) => dispatch(setTab(category_id, tab_id)),
    setSearchTerm: term => dispatch(setSearchTerm(term))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryItem)