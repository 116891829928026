import React from 'react'

import logo from '../../assets/img/3.png'

const BebidasAlcoolicasImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Bebidas Alcoolicas' style={{ width: '100%' }} />
  </div>
)

export default BebidasAlcoolicasImage