import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ProductOpened from './ProductOpened';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ProductInfo() {
  const query = useQuery();
  const [produtos, setProdutos] = useState([]);

  const adicionarProduto = (novoProduto) => {
    setProdutos([...produtos, novoProduto]);
  };

  return (
    <div>      
      <ProductOpened
        index={query.get('id')}        
        adicionarProduto={adicionarProduto}
      />
    </div>
  );
}

export default ProductInfo;
